import { Injectable } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { BaseReportingConfigProvider, IReportingConfig } from '@engineering11/reporting-web'
import { Store } from '@ngrx/store'
import { getCurrentToken, getCustomerKey$ } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class ReportingConfigProvider extends BaseReportingConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): IReportingConfig {
    return {
      reportingBaseURL: environment.services.reporting,
      customerKey$: this.store.pipe(getCustomerKey$),
      token$: this.store.select(getCurrentToken),
    }
  }
}
