import { ViewportScroller } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { InterviewQuestionStore } from '@employer/app/store/interview-question.store'
import { CustomerService } from '@engineering11/customer-web'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { isNotNil } from '@engineering11/utility'
import { Timestamp } from '@engineering11/types'
import { Store } from '@ngrx/store'
import { filter, firstValueFrom } from 'rxjs'
import { LocalStorageService, getCurrentUserEmployer } from 'shared-lib'
import { IInterviewQuestion } from '../../interview-question.model'

@Component({
  templateUrl: './interview-question-list.component.html',
  styleUrls: ['./interview-question-list.component.scss'],
})
export class InterviewQuestionsListComponent implements OnInit {
  user$ = this.store.pipe(getCurrentUserEmployer, filter(isNotNil))
  formVisible: boolean = false

  formIsOccupied: boolean = false

  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent

  questions$ = this.interviewQuestionStore.filteredQuestions$
  listLoaded$ = this.interviewQuestionStore.listLoaded$
  questionLoaded$ = this.interviewQuestionStore.questionLoaded$

  searchForm = new FormGroup({
    input: new FormControl(''),
  })

  selectedQuestion?: IInterviewQuestion | null
  deleteQuestion?: IInterviewQuestion | null

  constructor(
    private localStorageService: LocalStorageService,
    private customerService: CustomerService,
    private router: Router,
    private viewPortScroller: ViewportScroller,
    private interviewQuestionStore: InterviewQuestionStore,
    private store: Store
  ) {}

  async ngOnInit(): Promise<void> {
    const user = await firstValueFrom(this.user$)
    this.interviewQuestionStore.onGetAll(user.customerKey)
  }

  toggleCollapse() {
    this.formVisible = !this.formVisible
    this.formIsOccupied = false
  }

  editQuestion(question: IInterviewQuestion) {
    this.selectedQuestion = question
    this.formVisible = true
    this.viewPortScroller.scrollToPosition([0, 0])
    this.formIsOccupied = true
  }

  addQuestion() {
    this.toggleCollapse()
    this.selectedQuestion = undefined
  }

  questionSubmitted(question: Timestamp<IInterviewQuestion>) {
    if (!this.selectedQuestion) {
      this.interviewQuestionStore.onAddQuestion(question)
    } else {
      const interviewQuestion: Timestamp<IInterviewQuestion> = {
        ...question,
        id: this.selectedQuestion.id,
        date: this.selectedQuestion.date,
      }
      this.interviewQuestionStore.onUpdateQuestion(interviewQuestion)
    }
    this.selectedQuestion = undefined
  }

  questionDeleted(question: IInterviewQuestion) {
    this.confirmation.open()
    this.deleteQuestion = question
  }

  confirmationAnswer(res: boolean) {
    if (res) {
      this.interviewQuestionStore.onDeleteQuestion(this.deleteQuestion!.id)
      this.toggleCollapse()
    }
  }

  testSearchInput(input: Event) {
    const target = input.target as HTMLInputElement
    this.interviewQuestionStore.updateSearchFilter(target.value)
  }
}
