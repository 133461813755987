import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { FIREBASE_OPTIONS } from '@angular/fire/compat'
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore'
import { getFunctions, provideFunctions } from '@angular/fire/functions'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import * as fromStore from '@employer/app/store/index'
import { AnalyticsModule } from '@engineering11/analytics-web'
import { NgAisModule } from '@engineering11/angular-instantsearch'
import { FilesModule } from '@engineering11/files-web'
import { MapsModule } from '@engineering11/maps-web'
import { PaymentModule } from '@engineering11/payment-web'
import { REPORTING_CONFIG_TOKEN } from '@engineering11/reporting-web'
import { SearchModule } from '@engineering11/search-web'
import { SseClient } from '@engineering11/sse-client'
import { appConfigInitializerFn, AppConfigService, TenantBootstrapModule } from '@engineering11/tenant-bootstrap-web'
import { E11ExternalLinkModule } from '@engineering11/ui-lib/e11-external-link'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11NotificationsModule } from '@engineering11/ui-lib/e11-notifications'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { E11ToastModule } from '@engineering11/ui-lib/e11-toast'
import { E11ErrorModule, GlobalErrorHandler } from '@engineering11/web-api-error'
import { E11AlertNotificationService } from '@engineering11/web-ui-helpers'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { RECAPTCHA_SETTINGS, RecaptchaModule } from 'ng-recaptcha'
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger'
import { MarkdownModule } from 'ngx-markdown'
import { BrowserDetectionService } from 'projects/shared-lib/src/lib/service/browser-detection.service'
import { componentStateReducer, errorHandlerOverrides, INPUTS_MAXLENGTH_BY_CONTEXT, WebpackTranslateLoader } from 'shared-lib'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AccountDeactivationComponent } from './components/deactivation/deactivation.component'
import { ToastComponent } from './components/toast/toast.component'
import { E11ExternalLinkConfigProvider } from './config/e11-external-link.config'
import { CnectFilesConfigProvider } from './config/files.config'
import { PaymentConfigProvider } from './config/payment.config'
import { ReportingConfigProvider } from './config/reporting.config'
import { EmployerTenantBootstrapConfigProvider } from './config/tenant.config'
import { ServerErrorInterceptor } from './error-server.interceptor'
import { DenyGuard } from './guards/deny.guard'
import { FileNotFoundModule } from './modules/_404/404.module'
import { UiComponentLibraryModule } from './modules/_component-library/component-library.module'
import { SharedModule } from './modules/_shared/shared.module'
import { AuthenticationModule } from './modules/authentication/authentication.module'
import { CustomerModule } from './modules/customer/customer.module'
import { HomeModule } from './modules/home/home.module'
import { rollbarFactory } from './rollbar'
import { ConfigService } from './services/config.service'
// TODO: Try to remove lazy loaded modules from app.module - they should not be imported here unless absolutely necessary

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig()
}

@NgModule({
  declarations: [AppComponent, ToastComponent, AccountDeactivationComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        // useFactory: HttpLoaderFactory,
        // deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    MarkdownModule.forRoot({}),
    AnalyticsModule,
    CloudflareStreamModule,
    RecaptchaModule,
    SharedModule,
    UiComponentLibraryModule,
    AuthenticationModule,
    CustomerModule,
    HomeModule,
    FileNotFoundModule,
    E11NotificationsModule.forRoot(),
    E11ToastModule.forRoot(),
    E11InputModule.forRoot({ maxLengthByContext: INPUTS_MAXLENGTH_BY_CONTEXT }),
    E11TextareaModule.forRoot({ maxLengthByContext: INPUTS_MAXLENGTH_BY_CONTEXT }),
    E11ExternalLinkModule.forRoot({ configProvider: E11ExternalLinkConfigProvider }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => initializeFirestore(getApp(), { ignoreUndefinedProperties: true })),
    provideFunctions(() => getFunctions()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    NgAisModule.forRoot(),
    HttpClientModule,
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    StoreModule.forRoot({ ...fromStore.reducers, componentStore: componentStateReducer }),
    EffectsModule.forRoot(fromStore.effects),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50, connectInZone: true }) : [],
    FilesModule.forRoot({ configProvider: CnectFilesConfigProvider }),
    E11ErrorModule.forRoot({
      errorTrackerFactory: rollbarFactory,
      logger: NGXLogger,
      errorHandlerMap: errorHandlerOverrides,
      alertService: E11AlertNotificationService,
      globalErrorMapper: [],
    }),
    SearchModule,
    TenantBootstrapModule.forRoot({
      configProvider: EmployerTenantBootstrapConfigProvider,
    }),
    MapsModule.forRoot({ mapsApiKey: environment.firebaseConfig.apiKey }),
    PaymentModule.forRoot({
      configProvider: PaymentConfigProvider,
      // stripePublishableKey: environment.stripePublishableKey
    }),
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    DenyGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: (appConfig: AppConfigService) => {
        return { siteKey: appConfig.config.auth.reCaptchaSiteKey }
      },
      deps: [AppConfigService],
    },
    {
      provide: SseClient,
      useValue: new SseClient({
        projectId: environment.firebaseConfig.projectId,
        endpoint: environment.services.sse,
        suppressLogging: environment.production,
      }),
    },
    // { provide: TENANT_ID, useValue: environment.tenantId },
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: { siteKey: environment.v2ReCaptcha } as RecaptchaSettings,
    // },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: REPORTING_CONFIG_TOKEN, useClass: ReportingConfigProvider },
    BrowserDetectionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
