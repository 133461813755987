import { Injectable } from '@angular/core'
import { includesMatch } from '@engineering11/utility'
import { E11Error, E11ErrorHandlerService } from '@engineering11/web-api-error'
import { Timestamp } from '@engineering11/types'
import { filterByKeys } from '@engineering11/web-utilities'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Observable, mergeMap, switchMap } from 'rxjs'
import { QuestionType } from 'shared-lib'
import { AddInterviewQuestionRequest, IInterviewQuestion } from '../modules/interview-question-library/interview-question.model'
import { InterviewQuestionService } from '../services/interview-question.service'

export interface InterviewQuestionState extends EntityState<Timestamp<IInterviewQuestion>> {
  interviewQuestions: Timestamp<IInterviewQuestion>[]
  searchFilter: string | null
  inputFilter: string | null
  authorFilter: string | null
  listLoaded: boolean
  questionLoaded: boolean
}

export const feedbackAdapter: EntityAdapter<Timestamp<IInterviewQuestion>> = createEntityAdapter<Timestamp<IInterviewQuestion>>({
  selectId: question => question.id,
})

const defaultState: InterviewQuestionState = feedbackAdapter.getInitialState({
  interviewQuestions: [],
  searchFilter: null,
  inputFilter: null,
  authorFilter: null,
  listLoaded: false,
  questionLoaded: true,
})

@Injectable({
  providedIn: 'root',
})
export class InterviewQuestionStore extends ComponentStore<InterviewQuestionState> {
  constructor(private interviewQuestionService: InterviewQuestionService, private errorHandler: E11ErrorHandlerService) {
    super(defaultState)
  }

  // SELECTORS
  readonly interviewQuestions$ = this.select(s => s.interviewQuestions)
  readonly authors$ = this.select(this.interviewQuestions$, interviewQuestions => new Set(interviewQuestions.map(q => q.authorName)))
  readonly listLoaded$ = this.select(s => s.listLoaded)
  readonly questionLoaded$ = this.select(s => s.questionLoaded)
  readonly searchFilter$ = this.select(s => s.searchFilter)
  readonly inputFilter$ = this.select(s => s.inputFilter)
  readonly authorFilter$ = this.select(s => s.authorFilter)

  readonly filteredQuestions$ = this.select(
    this.interviewQuestions$,
    this.searchFilter$,
    this.inputFilter$,
    this.authorFilter$,
    (interviewQuestions, searchFilter, inputFilter, authorFilter) => {
      let filteredResult
      if (!searchFilter) filteredResult = interviewQuestions
      else filteredResult = filterByKeys(searchFilter, interviewQuestions, ['question', 'authorName', 'inputType'], this.inputIncludesMatch)
      if (inputFilter) filteredResult = filterByKeys(inputFilter, filteredResult, ['inputType'], includesMatch)
      if (authorFilter) filteredResult = filterByKeys(authorFilter, filteredResult, ['authorName'], includesMatch)

      return filteredResult
    }
  )

  // EFFECTS
  readonly onGetAll = this.effect((customerKey$: Observable<string>) =>
    customerKey$.pipe(
      switchMap(customerKey => {
        this.setListLoading()
        return this.interviewQuestionService.getAll(customerKey).pipe(
          tapResponse(
            interviewQuestions => this.loadInterviewQuestions(interviewQuestions),
            (err: E11Error) => this.errorHandler.handleE11Error(err)
          )
        )
      })
    )
  )

  readonly onAddQuestion = this.effect((question$: Observable<AddInterviewQuestionRequest>) =>
    question$.pipe(
      mergeMap(question => {
        this.setQuestionLoading()
        return this.interviewQuestionService.addQuestion(question).pipe(
          tapResponse(
            res => {
              return this.onAdded(res)
            },
            (err: E11Error) => this.errorHandler.handleE11Error(err)
          )
        )
      })
    )
  )

  readonly onUpdateQuestion = this.effect((question$: Observable<Timestamp<IInterviewQuestion>>) =>
    question$.pipe(
      mergeMap(question => {
        this.setQuestionLoading()
        return this.interviewQuestionService.updateQuestion(question).pipe(
          tapResponse(
            res => {
              return this.onUpdated(question)
            },
            (err: E11Error) => this.errorHandler.handleE11Error(err)
          )
        )
      })
    )
  )

  readonly onDeleteQuestion = this.effect((questionId$: Observable<string>) =>
    questionId$.pipe(
      mergeMap(questionId => {
        return this.interviewQuestionService.deleteQuestion(questionId).pipe(
          tapResponse(
            () => {
              return this.onDeleted(questionId)
            },
            (err: E11Error) => this.errorHandler.handleE11Error(err)
          )
        )
      })
    )
  )

  // UPDATERS
  readonly loadInterviewQuestions = this.updater((state, interviewQuestions: Timestamp<IInterviewQuestion>[]) => ({
    ...state,
    interviewQuestions: interviewQuestions || [],
    listLoaded: true,
  }))

  readonly setListLoading = this.updater(state => ({
    ...state,
    listLoaded: false,
  }))

  readonly setQuestionLoading = this.updater(state => ({
    ...state,
    questionLoaded: false,
  }))

  readonly onAdded = this.updater((state, question: Timestamp<IInterviewQuestion>) =>
    feedbackAdapter.addOne(question, { ...state, questionLoaded: true })
  )

  readonly onUpdated = this.updater((state, question: Timestamp<IInterviewQuestion>) =>
    feedbackAdapter.updateOne({ id: question.id, changes: question }, { ...state, questionLoaded: true })
  )

  readonly onDeleted = this.updater((state, questionId: string) => feedbackAdapter.removeOne(questionId, { ...state }))

  readonly updateSearchFilter = this.updater((state, searchFilter: string | null) => ({
    ...state,
    searchFilter,
  }))

  readonly updateInputFilter = this.updater((state, inputFilter: string | null) => ({
    ...state,
    inputFilter,
  }))

  readonly updateAuthorFilter = this.updater((state, authorFilter: string | null) => ({
    ...state,
    authorFilter,
  }))

  // UTILTIY
  inputIncludesMatch(queryText: string, itemText: string): boolean {
    const displayText = 'Text'
    if (itemText === QuestionType.Essay) {
      // Or included to cover edge case where user or question was named exactly 'Essay'
      return displayText.toLowerCase().includes(queryText.toLowerCase()) || itemText.toLowerCase().includes(queryText.toLowerCase())
    }
    return includesMatch(queryText, itemText)
  }
}
