import { Injectable } from '@angular/core'
import { IJobApplicationDialogueView, IJobApplicationView, IJobPostApplicationViews } from '@employer/app/models/job-application-views.model'
import { CollectionRepository, FieldValue } from '@engineering11/web-api-firebase'
import { COLLECTIONS } from 'shared-lib'

/**
 * TODO: Consider bringing as subcollection under job
 *
 */

@Injectable({ providedIn: 'root' })
export class JobApplicationViewsRepository extends CollectionRepository<IJobPostApplicationViews> {
  protected COLLECTION = COLLECTIONS.JOB_APPLICATION_VIEWS

  getForUserByJob(jobPostId: string, userId: string) {
    const id = buildApplicationViewsId({ jobPostId, userId })
    return this.get(id)
  }

  markApplicationViewed(view: IJobApplicationView) {
    const id = buildApplicationViewsId(view)
    return this.upsert({ id, userId: view.userId, jobPostId: view.jobPostId, applicationIds: FieldValue.arrayUnion(view.applicationId) })
  }

  markApplicationDialogueViewed(view: IJobApplicationDialogueView) {
    const id = buildApplicationViewsId(view)
    return this.upsert({ id, userId: view.userId, jobPostId: view.jobPostId, dialogueIds: FieldValue.arrayUnion(view.dialogueId) })
  }
}

export function buildApplicationViewsId(views: Pick<IJobPostApplicationViews, 'jobPostId' | 'userId'>) {
  return `${views.jobPostId}_${views.userId}`
}
