import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { IReportFilteringFormConfig, SDKReportFilteringComponent, dateBetween } from '@engineering11/reporting-web'
import {
  e11FieldInputCheckBox,
  e11FieldInputDatePicker,
  e11FieldInputMultiSelect,
  e11FormFor,
  e11FormLayoutFullWidth,
  e11FormLayoutInlineGrid,
} from '@engineering11/ui-lib/e11-form-builder'
import { mapToCheckboxOptions } from '@engineering11/ui-lib/e11-input-checkbox'
import { E11MultiSelectModule, IMultiSelectOption } from '@engineering11/ui-lib/e11-multi-select'
import { conditions } from '@parse-it/database'
import { employmentTypeMappings } from 'shared-lib'
import { IReportFilteringForm } from '../report-filtering-nav.component'
import { ReportJobStatus, experienceLevel } from '../report-filtering.model'
@Component({
  selector: 'report-filter-form',
  standalone: true,
  imports: [CommonModule, E11MultiSelectModule, SDKReportFilteringComponent],
  template: `
    <div *ngIf="!isSingleJobPostRoute">
      <!-- report filtering form sdk -->
      <sdk-report-filtering [form]="form" [loading]="loading" [filterConfig]="filterConfig"> </sdk-report-filtering>
    </div>
  `,
})
export class ReportFilterFormComponent implements OnInit, OnChanges {
  @Input() location: IMultiSelectOption[] = []
  @Input() cityOptions?: IMultiSelectOption[]
  @Input() department: IMultiSelectOption[] = []
  @Input() loading: boolean = false
  @Input() dateType: string = '__updatedAt'
  @Input() isSingleJobPostRoute: boolean = false
  filterConfig: IReportFilteringFormConfig<IReportFilteringForm> = {}
  form = this.buildFilterForm()

  employmentType: IMultiSelectOption[] = []
  experienceLevel = experienceLevel
  jobPostStatus = mapToCheckboxOptions(
    Object.entries(ReportJobStatus).map(([key, value]) => ({ key, value })),
    d => d.value,
    d => d.key,
    {
      containerClass: 'e11-mb-2',
    }
  )

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location || changes.department) {
      this.form = this.buildFilterForm()
    }
  }
  getEmploymentType() {
    return employmentTypeMappings.forEach((key, value) => this.employmentType.push({ label: key, value: value }))
  }
  ngOnInit(): void {
    this.getEmploymentType()

    this.filterConfig = {
      valueChange: {
        transform: (props: IReportFilteringForm) => {
          const selectedOptions = this.jobPostStatus.map(s => (props[s.value as keyof typeof props] ? s.value : '')).filter(s => s !== '')
          return [
            ...this.addConditionIfNotEmpty(selectedOptions, () => this.createInCondition('status', selectedOptions)),
            ...this.addConditionIfNotEmpty(props['locationAddress'], () =>
              this.createJsonValueCondition('locationAddress', '$.name', props['locationAddress'])
            ),
            ...this.addConditionIfNotEmpty(props['city'], () => this.createJsonValueCondition('locationAddress', '$.city', props['city'])),
            ...this.addConditionIfNotEmpty(props['department'], () => this.createInCondition('department', props['department'])),
            ...this.addConditionIfNotEmpty(props['employmentType'], () => this.createInCondition('employmentType', props['employmentType'])),
            ...this.addConditionIfNotEmpty(props['experienceLevel'], () => this.createInCondition('experienceLevel', props['experienceLevel'])),
            ...this.addDateCondition(this.dateType, props['startDate'], props['endDate']),
          ]
        },
      },
    }
  }
  createInCondition(column: string, value: string[]) {
    return conditions([{ column, operator: 'IN', value }].filter(v => v.value))
  }
  createJsonValueCondition(baseColumn: string, jsonPath: string, value: string[]) {
    return conditions(
      [
        {
          column: `JSON_VALUE(${baseColumn},'${jsonPath}')`,
          operator: 'IN',
          value,
        },
      ].filter(v => v.value)
    )
  }

  addConditionIfNotEmpty(props: string[], conditionFn: () => any) {
    return props && props.length > 0 ? [conditionFn()] : []
  }
  addDateCondition(dateType: string, startDate?: string, endDate?: string) {
    if (!startDate && !endDate) return []
    const dateValues = [
      ...[startDate ? new Date(startDate).toISOString() : new Date().toISOString()],
      ...[endDate ? new Date(endDate).toISOString() : new Date().toISOString()],
    ]
    return [dateBetween(`STRING(timestamp(timestamp_seconds(INT64(${dateType}._seconds))))`, dateValues)]
  }

  buildFilterForm() {
    return e11FormFor<IReportFilteringForm>()([
      e11FormLayoutFullWidth([
        e11FieldInputDatePicker('startDate', 'Start Date', undefined, {
          validatorOrOpts: [],
          extraInputOption: {
            maxYearSelectable: new Date().getFullYear(),
            classOverride: 'e11-border-skin-primary-accent e11-text-skin-primary-accent e11-mb-4',
            showLeadingIcon: true,
            showTrailingIcon: true,
          },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputDatePicker('endDate', 'End Date', undefined, {
          validatorOrOpts: [],
          extraInputOption: {
            maxYearSelectable: new Date().getFullYear(),
            classOverride: 'e11-border-skin-primary-accent e11-text-skin-primary-accent e11-mb-4',
            showLeadingIcon: true,
            showTrailingIcon: true,
          },
        }),
      ]),
      e11FormLayoutInlineGrid(
        [
          e11FieldInputCheckBox('jobStatus', 'Status', this.jobPostStatus, undefined, {
            validatorOrOpts: [],
            extraInputOption: { showResetButton: true, containerClass: "''" },
          }),
        ],
        {
          templateSpec: [
            {
              templateKey: 'divider',
              templatePosition: 'bottom',
            },
          ],
        }
      ),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('locationAddress', 'Location', this.location, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('city', 'City', this.cityOptions ?? [], undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('department', 'Department', this.department, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('employmentType', 'Employment Type', this.employmentType, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('experienceLevel', 'Experience Level', this.experienceLevel, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
    ])
  }
}
