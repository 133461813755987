import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ReportNavService {
  private reportDateTypeSubject = new BehaviorSubject<string>('__updatedAt')
  reportDateType$ = this.reportDateTypeSubject.asObservable()

  updateReportData(data: string) {
    this.reportDateTypeSubject.next(data)
  }
}
