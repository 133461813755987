import { Injectable } from '@angular/core'
import { AtLeast, Timestamp } from '@engineering11/types'
import { BaseFirestoreRepository, FieldValue, FirestoreQueryBuilder, IRepository, OrderByDirection } from '@engineering11/web-api-firebase'
import { Observable } from 'rxjs'
import { APPLICATION_STATE, COLLECTIONS, IJobPostApplication } from 'shared-lib'
@Injectable({ providedIn: 'root' })
export class JobPostApplicationRepository extends BaseFirestoreRepository<IJobPostApplication> implements IRepository<IJobPostApplication> {
  getValueChanges(jobPostId: string, id: string): Observable<IJobPostApplication | undefined> {
    const path = this.buildPath(jobPostId, id)
    return super._getValueChanges(path)
  }
  getAllValueChanges(jobPostId: string): Observable<Timestamp<IJobPostApplication>[]> {
    const path = this.buildPath(jobPostId)
    return super._getAllValueChanges(path)
  }

  getAllValueChangesByStatus(jobPostId: string, status: APPLICATION_STATE) {
    const path = this.buildPath(jobPostId)
    const query = new FirestoreQueryBuilder().where('applicationState', '==', status).orderBy('__createdAt', OrderByDirection.Desc).limit(100).build()
    return super._getInfiniteScroll(path, query, '__createdAt', OrderByDirection.Desc)
  }

  getCountByStatus(jobPostId: string, status: APPLICATION_STATE) {
    const path = this.buildPath(jobPostId)
    const query = new FirestoreQueryBuilder().where('applicationState', '==', status).build()
    return super._queryCount(path, query)
  }
  get(jobPostId: string, id: string): Observable<IJobPostApplication | undefined> {
    const path = this.buildPath(jobPostId, id)
    return super._get(path)
  }
  getAll(jobPostId: string): Observable<IJobPostApplication[]> {
    const path = this.buildPath(jobPostId)
    return super._getAll(path)
  }
  add(model: IJobPostApplication): Promise<any> {
    const path = this.buildPath(model.jobPostId)
    return super._add(model, path)
  }
  set(model: IJobPostApplication): Promise<any> {
    model.id = model.id || this.createId()
    const path = this.buildPath(model.jobPostId, model.id)
    return super._set(model, path)
  }
  update(
    model: AtLeast<
      {
        [Key in keyof IJobPostApplication]: Key extends 'id' | 'jobPostId' ? IJobPostApplication[Key] : IJobPostApplication[Key] | FieldValue
      },
      'id' | 'jobPostId'
    >
  ): Promise<any> {
    // return this.localasf.doc(this.buildPath(model.jobPostId, model.id)).update(model)
    const path = this.buildPath(model.jobPostId, model.id)
    return super._update(model, path)
  }
  delete(jobPostId: string, id: string): Promise<any> {
    const path = this.buildPath(jobPostId, id)
    return super._delete(path)
  }

  protected validateOnCreate(model: IJobPostApplication) {
    if (!model.jobPostId) {
      throw new Error('Job Application model must have a jobPostId')
    }
    super.validateOnCreate(model)
  }
  /**
   * Will be changed to nest under job posting collection
   * @param id
   * @returns
   */
  protected buildPath(jobId: string, id = ''): string {
    return `${COLLECTIONS.JOB_POST}${jobId}${COLLECTIONS.JOB_POST_APPLICATION}${id}`
  }
}
